import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Footer } from '../components/Footer';
import { SiteHeader } from '../components/header/SiteHeader';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import { colors } from '../styles/colors';
import { inner, outer, SiteMain } from '../styles/shared';
import { AuthorYaml } from '../templates/author';
import { NoImage, PostFull } from '../templates/post';

const PageTemplate = css`
  .site-main {
    margin-top: 64px;
    padding-bottom: 4vw;
    background: #fff;
  }

  // #serviceなどへのリンクをクリックした際に、ヘッダの文だけ下にずれた位置に遷移するための設定
  .fragment-link {
    margin-top: -64px;
    padding-top: 64px;
  }

  .heading {
    text-align: center;
    margin-bottom: 20px;
  }
`;

const Contact: React.FC = () => (
  <a href="https://forms.gle/Cju7iHTmYrKKuP1P6">お問い合わせはこちら</a>
);

interface AllAuthorYamlEdge {
  node: AuthorYaml;
}

interface IndexProps {
  data: {
    logo: {
      childImageSharp: {
        fluid: any;
      };
    };
    allAuthorYaml: {
      edges: [AllAuthorYamlEdge];
    };
    topImage: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    aiSpreadImage: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    mlopsImage: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    seminarImage: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
    consultingImage: {
      childImageSharp: {
        fluid: FluidObject;
      };
    };
  };
}

const Index = ({ data }: IndexProps) => {
  const members = data.allAuthorYaml.edges.map(e => e.node);

  return (
    <IndexLayout>
      <Helmet>
        <title>StudyCo</title>
      </Helmet>
      <Wrapper css={PageTemplate}>
        <SiteHeader isHome={false} />
        <main id="site-main" className="site-main" css={[SiteMain, outer]}>
          <div css={inner}>
            <article className="post page" css={[PostFull, NoImage]}>
              <section>
                <div css={TopParentContent}>
                  <div css={TopMessageContent}>
                    <h1>StudyCo</h1>
                    <p>
                      StudyCoは、アプリケーションの設計・実装から、統計・機械学習、ブロックチェーン、量子コンピュータなど、ITを中心に幅広く調査・実践しているコミュニティです。
                      各種テーマの勉強会の開催やアプリケーション開発などを実践してきています。
                      そんな私たちは、現在LLM周辺分野に最も力を入れています。
                    </p>
                  </div>
                  <Img
                    css={FullImage}
                    fluid={data.topImage.childImageSharp.fluid}
                    alt="トップページのイメージ"
                  />
                </div>
              </section>
              <section>
                <div css={TwoColumn}>
                  <h2 className="heading">LLMに注目する理由</h2>
                  <div className="wrapper">
                    <Img
                      className="column image"
                      fluid={data.aiSpreadImage.childImageSharp.fluid}
                      alt="AIの浸透 画像"
                    />
                    <div className="column text">
                      <h3>AIの浸透</h3>
                      <p>
                        ChatGPTの登場以来、今まで以上にAIが一般に浸透してきました。 LLM（Large
                        Language
                        Model：大規模言語モデル）を自社のシステムに組み込みたいと考える企業も増えています。
                        機械学習に専門性のなかったエンジニアが、仕事で機械学習に関わる機会もとても増えています。
                        しかし、LLMを実際のシステムに組み込み、ビジネスに活かすのは簡単ではありません。
                      </p>
                    </div>
                  </div>
                </div>
                <div css={TwoColumn}>
                  <div className="wrapper">
                    <div className="column text">
                      <h3>LLMをシステムに組み込む難しさ</h3>
                      <p>
                        機械学習システムには、分析環境の整備、機械学習のコードのテスト、モデルのデプロイ、モデルとデータの監視、自動化、などのいわゆるシステム運用が求められます。
                        LLMを使ったシステムも例外ではありません。
                        細かいチューニングや評価など、実際のシステムにLLMを組み込もうとすると発生する課題は多いです。
                        私たちは、企業システムへのLLMの導入に、研修・コンサルティング・技術検証といったサービスを提供しています。
                      </p>
                    </div>
                    <Img
                      className="column image"
                      fluid={data.mlopsImage.childImageSharp.fluid}
                      alt="MLOPSの重要性 画像"
                    />
                  </div>
                </div>
              </section>
              <section id="services" className="fragment-link">
                <h2 className="heading">サービス</h2>
                <div css={TwoColumn}>
                  <div className="wrapper">
                    <Img
                      className="column image"
                      fluid={data.seminarImage.childImageSharp.fluid}
                      alt="セミナー・研修 画像"
                    />
                    <div className="column text">
                      <h3>セミナー・研修</h3>
                      <div>
                        <p>
                          機械学習（LLM含む）やその周辺分野を中心に、ご要望に応じたテーマのセミナー・研修をご提供します。
                        </p>
                        <p css={ExampleTitle}>セミナーテーマの例</p>
                        <div css={Example}>
                          <ul>
                            <li>機械学習入門</li>
                            <li>LLMを使ったアプリケーション開発の入門</li>
                          </ul>
                          <p>
                            <a href="https://studyco.connpass.com/">conpass</a>
                            でも定期的に公開セミナーを行っています。
                          </p>
                        </div>
                        <div css={Annotation}>
                          <p>
                            ※
                            セミナー開催は、法人様でも、法人ではないコミュニティ様でもご相談可能です。
                          </p>
                          <p>
                            ※
                            勉強会コミュニティ様には、勉強会の運営サポートや、外部講師としての参加なども実施させていただきます。
                          </p>
                          <p>
                            ※ StudyCoの勉強会への登壇やコラボレーション企画などもご相談可能です。
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div css={TwoColumn}>
                  <div className="wrapper">
                    <div className="column text">
                      <h3>技術コンサルティング・技術顧問</h3>
                      <div>
                        <p>
                          機械学習（LLM含む）の検証や機械学習を活用したアプリケーションの開発等を支援させていただきます。
                        </p>
                        <p css={ExampleTitle}>サービス内容の例</p>
                        <div css={Example}>
                          <ul>
                            <li>機械学習導入に関するロードマップの作成</li>
                            <li>エンジニアの技術習得サポート</li>
                            <li>データ分析基盤の構築</li>
                            <li>データ分析コンサルティング</li>
                            <li>
                              具体的なツール・サービス等の技術調査や、プロトタイプアプリケーションの実装
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <Img
                      className="column image"
                      fluid={data.consultingImage.childImageSharp.fluid}
                      alt="技術コンサルティング・技術顧問 画像"
                    />
                  </div>
                </div>
              </section>
              <section id="member" className="fragment-link">
                <h2 className="heading">メンバー</h2>
                <div css={Members}>
                  <div className="wrapper">
                    {members.map((member, index) => {
                      return (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={index} className="member">
                          <div className="member-description">
                            <h3>{member.name}</h3>
                            <p>{member.bio}</p>
                          </div>
                          <Img
                            className="member-image"
                            fluid={member.top_image.childImageSharp.fluid}
                            alt={member.name}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </section>
              <section id="contact">
                <h2 className="heading">お問い合わせ</h2>
                <div css={ContactStyle}>
                  <p>
                    セミナーやコンサルティングなど、企業様・各種団体様からのお問い合わせを受け付けております。
                    <br />
                    StudyCoの勉強会への登壇やコラボレーション企画などもご相談可能です。
                    <br />
                    お気軽にお問い合わせください。
                  </p>
                  <Contact />
                </div>
              </section>
            </article>
          </div>
        </main>
        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

export const pageQuery = graphql`
  query {
    allAuthorYaml {
      edges {
        node {
          id
          name
          twitter
          bio
          profile_image {
            childImageSharp {
              fluid(maxWidth: 3720) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          avatar {
            childImageSharp {
              fluid(quality: 100, srcSetBreakpoints: [40, 80, 120]) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          top_image {
            childImageSharp {
              fluid(maxWidth: 3720) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    topImage: file(relativePath: { eq: "img/top-image-brain.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 3720) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    aiSpreadImage: file(relativePath: { eq: "img/ai-spread.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3720) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mlopsImage: file(relativePath: { eq: "img/mlops-importance.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3720) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    seminarImage: file(relativePath: { eq: "img/seminar.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3720) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    consultingImage: file(relativePath: { eq: "img/consulting.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3720) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const TopParentContent = css`
  position: relative;
`;

const TopMessageContent = css`
  position: absolute;
  z-index: 1;
  top: 50%;
  width: 500px;
  transform: translateY(-50%);
  margin: 10px;
  padding: 80px;
  background: rgba(255, 255, 255, 0.9);

  @media (max-width: 1170px) {
  }

  @media (max-width: 800px) {
    text-size: 80%;
    width: 100%;
    margin-left: 0px;
    padding: 10px;
  }

  @media (max-width: 500px) {
  }
`;

const FullImage = css`
  margin: 25px 0 50px;
  height: 800px;
  background: ${colors.lightgrey} center center;
  background-size: cover;
  border-radius: 5px;

  @media (max-width: 1170px) {
    margin: 25px -6vw 50px;
    border-radius: 0;
    img {
      max-width: 1170px;
    }
  }

  @media (max-width: 800px) {
    height: 400px;
  }
  @media (max-width: 500px) {
    margin-bottom: 4vw;
    height: 350px;
  }
`;

const TwoColumn = css`
  .column {
    height: 100%;
    width: 100%;
    margin-bottom: 20px;
  }

  .image {
    display: none;
    object-fit: cover;
  }

  .text {
    padding: 30px;
    background-color: #f1f1f1;
  }

  @media (min-width: 800px) {
    .wrapper {
      display: flex;
      height: 500px;
      margin-bottom: 20px;
    }
    .image {
      display: block;
    }
  }
`;

const Members = css`
  .wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .member {
    width: 50%;
    height: 400px;
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
  }

  .member-description {
    width: 60%;
    padding-left: 10px;
    padding-right: 10px;
    text-align: left;
  }

  .member-image {
    width: 40%;
  }

  @media (max-width: 800px) {
    .member {
      width: 100%;
      height: auto;
    }

    .member-description {
      width: 100%;
    }

    .member-image {
      width: 100%;
      height: 400px;
    }
  }
`;

const ExampleTitle = css`
  font-weight: bold;
  margin-bottom: 0.6em;
`;

const Example = css`
  margin-bottom: 1em;
  ul {
    margin-bottom: 0.6em;
  }
`;

const Annotation = css`
  p {
    margin-bottom: 0.4em;
  }
`;

const ContactStyle = css`
  text-align: center;
`;

export default Index;
